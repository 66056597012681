import c3api from "@/c3api";
import { ref } from "vue";
import { useToast } from "vue-toastification";

export default function useTimezones() {
  const timezonesLoading = ref(false);
  const timezones = ref([]);
  const toast = useToast();

  const fetchTimezones = async (params = null) => {
    try {
      timezonesLoading.value = true;
      const response = await c3api.get(`/system/timezones`);
      return (timezones.value = response.data.data);
    } catch (error) {
      toast.error(error.response?.data.data.join(". "));
    } finally {
      timezonesLoading.value = false;
    }
  };

  return {
    timezones,
    fetchTimezones,
    timezonesLoading,
  };
}
